<template>
  <ion-page>
    <AppHeader></AppHeader>
    
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <ion-icon :icon="personOutline"></ion-icon> 
            Account Settings
          </ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-list>
            <ion-item button @click="showProfile()" detail>
              <ion-label>
                Profile
              </ion-label>
            </ion-item>
            <ion-item button @click="doUpgrade()" detail disabled>
              <ion-label>
                Upgrade Account <br><small><i>Coming Soon...</i></small>
              </ion-label>
            </ion-item>
            <ion-item button @click="doLogout()" detail>
              <ion-label>
                Logout
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-modal
        :is-open="isProfileOpenRef"
        css-class="my-custom-class"
        @onDidDismiss="setProfileOpen(false)"
      >
        <ProfileForm :formData="formData" @close="closeProfileForm" @submit="submitProfileForm" />
      </ion-modal>
      
    </ion-content>
    
    <ion-footer>
      <!-- <ion-toolbar> -->
        <ion-text color="dark">
            <em class="appVersion">App version 20210116</em>
        </ion-text>
      <!-- </ion-toolbar> -->
    </ion-footer>

    <AppFooter />

  </ion-page>
</template>

<style scoped>
  .appVersion {
    font-size: 0.8em;
    margin: 10px;
  }
</style>

<script lang="ts">
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonItem,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonText,
  alertController
} from '@ionic/vue'
import { defineComponent, onMounted, ref } from 'vue'
import { personOutline } from 'ionicons/icons'
import { useRouter } from 'vue-router'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import useFirebaseAuth from '../hooks/firebase-auth'
// import useFirebaseFirestore from '../hooks/firebase-firestore'
import useGlobalHelpers from "../helpers/global-helpers"
import ProfileForm from '@/components/ProfileForm.vue'

export default defineComponent({
  name: 'AccountPage',
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonContent,
    IonFooter,
    IonItem,
    IonIcon,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonText,
    AppHeader,
    AppFooter,
    ProfileForm
  },
  setup() {
    const isProfileOpenRef = ref(false)
    const setProfileOpen = (state: any) => isProfileOpenRef.value = state

    const formData = {
      formDetail: {
        uid: null,
        name: null,
        email: null,
        birthDate: null,
        mobile: null
      } 
    }

    const { logout } = useFirebaseAuth()
    const router = useRouter()
    const doLogout = async () => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Confirm!',
          message: 'Do You Want to Logout?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'Okay',
              handler: async () => {
                await logout()
                localStorage.removeItem('selectedBook')
                router.replace({path:"/login"})
              },
            },
          ],
        });
      return alert.present()
    }

    const getProfileData = async () => {
      // console.log('getProfileData')
      const profileData: any = await useFirebaseAuth()
          .getUserData()
      // console.log(profileData)
      return profileData
    }

    const showProfile = async () => {
      // console.log('showProfile')
      const profileData = await getProfileData()
      Object.assign (formData, {
        formType: 'ChangeProfile',
        formDetail: {
          ...profileData
        }
      })
      setProfileOpen(true)
    }

    const submitProfileForm = async (event: any) => {
      // console.log(event)
      setProfileOpen(false)
      await useFirebaseAuth()
        .updateProfile(
          {...event.formData}
        )
      await useGlobalHelpers()
        .showAlerts('Update Profile', '', 'Profile Updated')
    }

    const closeProfileForm = (event: any) => {
      // console.log(event)
      event.action == 'close' ? setProfileOpen(false) : null
    }

    const doUpgrade = async () => {
      // console.log('do Upgrade Coming Soon')
      await useGlobalHelpers()
        .showAlerts('Upgrade Account', '', 'Coming Soon...')
    }

    onMounted ( async () => {
      await getProfileData()
    })

    return {
      router: useRouter(),
      isProfileOpenRef,
      setProfileOpen,
      doLogout,
      showProfile,
      doUpgrade,
      closeProfileForm,
      submitProfileForm,
      getProfileData,

      formData,

      personOutline
    }
  }
});
</script>