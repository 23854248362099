
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  // IonSelect,
  // IonSelectOption,
  IonTitle,
  IonToolbar 
} from '@ionic/vue'
import { close, checkmarkOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'

export default defineComponent({
  name: 'ProfileForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonDatetime,
    IonIcon,
    IonInput,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    // IonSelect,
    // IonSelectOption,
    IonTitle,
    IonToolbar
  },
  props: { 
    formData: {
      type: Object,
      default: () => ({
        formType: 'ChangeProfile',
        formDetail: { 
          uid: null,
          name: null,
          email: null,
          birthDate: null,
          mobile: null,
          settings: {
            categories: {
              income: [],
              expense: []
            }
          }
        }
      })
    }
  },
  setup (props: any, ctx) {
    const formItem = reactive<any>({
      uid: null,
      name: null,
      email: null,
      birthDate: null,
      mobile: null
    })

    const putTitle = computed(() => {
      const result = 'Profile'
      Object.assign (formItem, {
        uid: props.formData.formDetail.uid,
        name: props.formData.formDetail.name,
        email: props.formData.formDetail.email,
        birthDate: props.formData.formDetail.birthDate.toDate().toISOString(),
        mobile: props.formData.formDetail.mobile
      })
      console.log(formItem)
      return result
    })

    const submitForm = () => {
      // console.log('submit Income Form')
      // console.log(formItem)
      const response = {
        'action': 'submit',
        'formData': {
          'uid': formItem.uid,
          'email': formItem.email,
          'name': formItem.name,
          'birthDate': new Date (formItem.birthDate),
          'mobile': formItem.mobile
        }
      }
      // console.log(response)
      ctx.emit('submit', response)
    }
    
    const closeForm = () => {
      // console.log('close Income Form')
      const response = {
        'action': 'close',
        'formData': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,

      //data
      formItem,

      //computed
      putTitle,

      //icon
      close,
      checkmarkOutline
    }
  }
})
