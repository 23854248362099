<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="secondary" @click="closeForm">
            <ion-icon slot="icon-only" ios="close" md="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ putTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
      
    <ion-content class="ion-padding">
      <ion-card>
        <ion-card-content>
          <ion-item>
            <ion-label position="stacked">Email</ion-label>
            <ion-input v-model="formItem.email" type="email" disabled></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Birth Date</ion-label>
            <ion-datetime
              name="birthDate"
              display-format="DDDD MMM D, YYYY"
              placeholder="Select Date"
              v-model="formItem.birthDate"
            ></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Name</ion-label>
            <ion-input v-model="formItem.name" type="text"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Mobile</ion-label>
             <ion-input v-model="formItem.mobile" type="tel"></ion-input>
          </ion-item>
          <ion-item>
            <ion-button slot="end" @click="submitForm">
              <ion-icon :icon="checkmarkOutline"></ion-icon> Submit
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  // IonSelect,
  // IonSelectOption,
  IonTitle,
  IonToolbar 
} from '@ionic/vue'
import { close, checkmarkOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'

export default defineComponent({
  name: 'ProfileForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonDatetime,
    IonIcon,
    IonInput,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    // IonSelect,
    // IonSelectOption,
    IonTitle,
    IonToolbar
  },
  props: { 
    formData: {
      type: Object,
      default: () => ({
        formType: 'ChangeProfile',
        formDetail: { 
          uid: null,
          name: null,
          email: null,
          birthDate: null,
          mobile: null,
          settings: {
            categories: {
              income: [],
              expense: []
            }
          }
        }
      })
    }
  },
  setup (props: any, ctx) {
    const formItem = reactive<any>({
      uid: null,
      name: null,
      email: null,
      birthDate: null,
      mobile: null
    })

    const putTitle = computed(() => {
      const result = 'Profile'
      Object.assign (formItem, {
        uid: props.formData.formDetail.uid,
        name: props.formData.formDetail.name,
        email: props.formData.formDetail.email,
        birthDate: props.formData.formDetail.birthDate.toDate().toISOString(),
        mobile: props.formData.formDetail.mobile
      })
      console.log(formItem)
      return result
    })

    const submitForm = () => {
      // console.log('submit Income Form')
      // console.log(formItem)
      const response = {
        'action': 'submit',
        'formData': {
          'uid': formItem.uid,
          'email': formItem.email,
          'name': formItem.name,
          'birthDate': new Date (formItem.birthDate),
          'mobile': formItem.mobile
        }
      }
      // console.log(response)
      ctx.emit('submit', response)
    }
    
    const closeForm = () => {
      // console.log('close Income Form')
      const response = {
        'action': 'close',
        'formData': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,

      //data
      formItem,

      //computed
      putTitle,

      //icon
      close,
      checkmarkOutline
    }
  }
})
</script>