import { alertController } from '@ionic/vue';

export default function() {
  const showAlerts = async (header?: string, subHeader?: string, message?: string) => {
    const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: (header) ? header : 'Alert',
        subHeader: (subHeader) ? subHeader : '',
        message: (message) ? message : '',
        buttons: ['OK'],
      });
    return alert.present();
  }

  // const checkBookId = async (lastSelectedBookId: any) => {
  //   console.log('Last BookID: ' + lastSelectedBookId)
  //   const currentBook: any = await localStorage.getItem('selectedBook')
  //   const currentBookId = JSON.parse(currentBook).id
  //   console.log('current BookID: ' + currentBookId)
  //   if (currentBookId !== lastSelectedBookId) {
  //     console.log('Selected Book ID different')
  //     console.log('New BookId: ' + lastSelectedBookId)
  //     console.log('Need Update')
  //     return {status: 1, message: 'Need Update'}
  //   } else {
  //     console.log('Selected Book ID same')
  //     return {status: 0, message: 'No Need Update'}
  //   }
  // }

  return {
    // FUNCTIONS
    showAlerts,
    // checkBookId
  }
}